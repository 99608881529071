//import hook useState dan useEffect from react
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Container, Row, Col, Button, Table } from "react-bootstrap";
import axios from "axios";
import { URL_API } from "../../utils/api";

function PostIndex() {
  const URL = URL_API;

  //define state
  const [posts, setPosts] = useState([]);

  //useEffect hook
  useEffect(() => {
    fectData();
  }, []);

  //function "fetchData"
  const fectData = async () => {
    const response = await axios.get(`${URL}get-data`);
    const data = await response.data.data;
    setPosts(data);
  };

  //function "deletePost"
  const deletePost = async (id) => {
    try {
      var formdata = new FormData();
      formdata.append("id", id);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${URL}delete-data`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const parsedResult = JSON.parse(result);
          if (parsedResult.status === "200") {
            console.log("Post berhasil dihapus.");
            fectData();
          } else {
            console.error("Gagal menghapus post.");
          }
        })
        .catch((error) => console.log("error =>", error));
    } catch (error) {
      console.error("Terjadi kesalahan:", error);
    }
  };

  return (
    <Container className="mt-3">
      <Row>
        <Col md="{12}">
          <Card className="border-0 rounded shadow-sm">
            <Card.Body>
              <Button
                as={Link}
                to="/posts/create"
                variant="success"
                className="mb-3"
              >
                + TAMBAH DATA
              </Button>
              <Table striped bordered hover className="mb-1">
                <thead>
                  <tr>
                    <th>NO.</th>
                    <th>TITLE</th>
                    <th>CONTENT</th>
                    <th className="text-center">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {posts.map((post, index) => (
                    <tr key={post.id}>
                      <td>{index + 1}</td>
                      <td>{post.title}</td>
                      <td>{post.content}</td>
                      <td className="text-center">
                        <Button
                          as={Link}
                          to={`/posts/edit/${post.id}`}
                          variant="primary"
                          size="sm"
                          className="me-2"
                        >
                          EDIT
                        </Button>
                        <Button
                          onClick={() => deletePost(post.id)}
                          variant="danger"
                          size="sm"
                        >
                          DELETE
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default PostIndex;
